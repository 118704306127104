<template>
	<a @click="changePage" :href="getUrl('articolo/' + slug)" :title="title" class="article-card content-shadow">
		<div>
			<div class="article-image" :style="'background-image: url(' + image + ')'" />
			<br class="clear" />
			<div class="article-wrap">
				<div class="categories tiny">
					<div class="post-type">Articolo</div>

					<div class="separator"></div>
					<div class="category" v-for="(category, j) in categories" :key="j">
						<span class="category-name">{{ category.name }}</span>
						<span class="category-comma" v-if="j < categories.length - 1">,</span>
					</div>
				</div>
				<h4 class="color-aili-purple font-size-20" v-html="title" />
				<div class="author-wrap">
					<div
						class="author-image-single"
						v-if="author.images"
						:style="'background-image: url(' + author.images.thumbnail + ')'"
					></div>
					<h4 class="color-aili-black font-size-14 author-name">
						{{ author.first_name + ' ' + author.last_name }}
					</h4>
				</div>
				<!-- <div class="excerpt" v-html="excerpt" /> -->
			</div>
		</div>
	</a>
</template>
<script>
	export default {
		props: ['author', 'title', 'slug', 'image', 'refresh', 'excerpt', 'categories'],
		methods: {
			changePage(e) {
				e.preventDefault();
				if (this.refresh) {
					window.location.href = '/articolo/' + this.slug;
				} else {
					this.goTo('Article', { slug: this.slug });
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	a {
		text-decoration: none;
	}
	.author-name {
		line-height: 40px;
		margin-bottom: 25px;
		margin-left: 50px;
		line-height: 1.4;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.author-wrap {
		position: relative;
	}
	.author-image-single {
		margin-bottom: 15px;
		height: 40px;
		width: 40px;
		background-size: contain;
		border-radius: 100%;
		margin-right: 14px;
	}
	.categories {
		margin-bottom: 6px;
		font-size: 12px;
		text-transform: uppercase;
		color: black;
	}
	.excerpt {
		position: relative;
		float: none;
		clear: both;
		width: 100%;
		margin-top: $spacing-1;
	}

	.article-card {
		cursor: pointer;
		background: #fff;
		position: relative;
		display: inline-block;
		width: 30%;
		min-width: 260px;
		min-height: 350px;
		/* margin-bottom: $spacing-1;
	margin-right: $spacing-0; */
		margin: 0 $spacing-0/3 * 2 $spacing-1;

		@media (max-width: $mobile-m) {
			margin: 0 $spacing-0/3 * 2 $spacing-1;
		}

		.preview_data {
			border-top: 1px solid #fed3e7;
			padding-top: 10px;
			bottom: 10px;
			width: 100%;
			position: absolute;
		}
		.columns-4 {
			float: left;
			width: 33%;
		}
		a {
			display: inline-block;
			width: 100%;
			text-decoration: none;
		}

		.article-image {
			float: left;
			width: 100%;
			padding-top: 60%;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.article-wrap {
			position: relative;
			padding: $spacing-0;
		}
		h4 {
			margin-bottom: 15px;
		}
	}
</style>
