<template>
	<div>
		<div class="buttons">
			<div @click="prevPage"><Arrow type="left" color="#7209b7" /></div>
			<div @click="nextPage">
				<Arrow @click="prevPage" type="right" color="#7209b7" />
			</div>
		</div>

		<div class="the-swiper">
			<swiper
				class="last-post-by-category-swiper"
				ref="swiperRecipes"
				:options="swiperOption"
				@slideChange="onSlideChange"
			>
				<swiper-slide v-for="(card, i) in cards" :key="i">
					<CardSingleArticle
						:slug="card.slug"
						:image="card.featured_media_data.medium"
						:title="card.title.rendered"
						:author="card.author_data"
						:excerpt="card.post_excerpt"
						:categories="card.category_data"
					/>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
	</div>
</template>

<script>
import Arrow from "@/components/ui/Arrow.vue";
import CardSingleArticle from "@/components/ui/CardSingleArticle.vue";

export default {
	name: "LastPostsByCategory",
	props: ["block", "blocks", "parentBlock", "category", "color"],
	components: {
		CardSingleArticle,
		Arrow
	},
	data() {
		return {
			cards: [],
			currentIndex: 0,
			swiperOption: {
				slidesPerView: 1,
				//spaceBetween: 25,
				centeredSlides: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				breakpoints: {
					768: {
						slidesPerView: 3,
						//spaceBetween: 20
					}
				}
			}
		};
	},
	async mounted() {
		if (this.category) {
			let response_last_posts = await this.$api.get("/wp/v2/posts", {
				params: {
					category_slug: this.category
				}
			});
			this.cards = response_last_posts.data;
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiperRecipes.$swiper;
		}
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		}
	}
};
</script>

<style lang="scss" scoped>
.the-swiper {
	width: calc(100% - 110px);
	margin-left: 80px;
	@media (max-width: $tablet-l) {
		margin: auto;
		width: 100%;
	}
}
.card {
	min-height: 400px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	text-decoration: none;
	margin-left: $spacing-0;
	margin-right: $spacing-0;
	.news-image {
		height: 250px;
		background-size: cover;
		background-position: center;
	}
}
.article-card {
	margin-left: $spacing-0;
	margin-right: $spacing-0;
	
}

.link {
	text-decoration: none;
	display: inline-block;
}
.swiper-slide {
	* {
		text-align: left;
	}
	@media (max-width: $tablet-l) {
		text-align: center;
	}
}

h3 {
	padding: $spacing-0;
}

.gradient:before {
	content: "";
	background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.buttons {
	padding: 0px 20px;
	position: absolute;
	@media (max-width: $tablet-m) {
		display: none;
	}
}
</style>

<style lang="scss">
.last-post-by-category-swiper {
	//Class inside swiper need to be declared outside of scoped
	.swiper-pagination-bullet-active {
		background-color: $aili-purple;
	}
}
</style>
